footer{
    color: white;
    background-color: rgb(33, 37, 41);
    text-align: center;
    font-family: 'proxima-nova' sans-serif;
    font-size: 16px;
    font-weight: normal;
    padding: 40px;
    width: 100%;
    position: relative;
    bottom: 0px;
    a{
        color: white;
        transition: 0.3s;
        &:hover{
            color: brown;
        }
    }
    p{
        margin:0
    }
}